<script>
import {
  SuawMainContent,
  SuawSection,
  SuawForm,
  SuawHeading,
  SuawTextInput,
  SuawParagraph,
  SuawButton,
  SuawCheckbox,
  SuawSelect,
  SuawInputGroup,
  SuawRadio,
  SuawLabel,
  SuawDropdown,
  SuawFileUpload
} from "@suaw/suaw-component-library";
import { ListCountries } from "../../operations.gql";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import * as UserApi from "../../api.js";
import { getAvatarColorOptions } from "../../common.js";
import { convertFileToBase64String } from "@/utils/formatting/files.js";

export default {
  name: "CompleteAccount",
  components: {
    SuawMainContent,
    SuawSection,
    SuawForm,
    SuawHeading,
    SuawTextInput,
    SuawParagraph,
    SuawButton,
    SuawCheckbox,
    SuawSelect,
    SuawInputGroup,
    SuawRadio,
    SuawLabel,
    SuawDropdown,
    SuawFileUpload
  },
  data() {
    return {
      activeForm: "verification",
      countries: [],
      verificationForm: {
        token: ""
      },
      avatarStyle: "color",
      avatarColorOptions: getAvatarColorOptions(),
      completeAccountForm: {
        firstName: "",
        lastName: "",
        isLastNameHidden: false,
        password: "",
        confirmPassword: "",
        countryAbbrev: null,
        postalCode: "",
        avatarColor: "blue",
        avatarUrl: "",
        unitSystem: "imperial",
        agreementCheckbox: false
      }
    };
  },
  validations: {
    verificationForm: {
      token: { required }
    },
    completeAccountForm: {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
      countryAbbrev: {
        required
      },
      postalCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10)
      },
      agreementCheckbox: {
        required
      }
    }
  },
  apollo: {
    countries: {
      query: ListCountries
    }
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.completeAccountForm.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.completeAccountForm.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    },
    formattedCountries() {
      return this.countries.map(country => ({
        text: country.name,
        value: country.abbrev,
        key: country.abbrev
      }));
    },
    showImageUpload() {
      return this.avatarStyle === "image";
    }
  },
  mounted() {
    const { token: refreshToken } = this.$route.params;
    if (refreshToken) {
      this.loginByToken(refreshToken);
    }
    if (this.$auth.user && this.$auth.user.email_verified) {
      this.completeAccountForm.firstName = this.$auth.user.first_name;
      this.completeAccountForm.lastName = this.$auth.user.last_name;
      this.activeForm = "complete-account";
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    uppercaseToken() {
      this.verificationForm.token = this.verificationForm.token.toUpperCase();
    },
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.completeAccountForm.avatarUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    handleAvatarColorSelect(avatar) {
      this.completeAccountForm.avatarColor = avatar.avatarType;
    },
    redirectToSignIn() {
      this.$router.push({ name: "SignIn" });
    },
    async loginByToken(token) {
      const result = await UserApi.loginByToken(token);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      // begin rare case of doing more stuff inside the helper method before returning its result
      const { accessToken, user } = result.result.value || {};
      this.$auth.refreshAuthUser({ accessToken, user });
      this.completeAccountForm.firstName = this.$auth.user.first_name;
      this.completeAccountForm.lastName = this.$auth.user.last_name;
      this.activeForm = "complete-account";
      this.$router.push({ name: "CompleteAccountToken", params: { token } });
      // end rare case of doing more stuff inside the helper method before returning its result
      return result;
    },
    async resetPassword() {
      const result = await UserApi.resetPassword(this.$auth.user.id, this.completeAccountForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async UploadUserAvatar() {
      const { avatarUrl } = this.completeAccountForm;
      const result = await UserApi.uploadUserAvatar(this.$auth.user.id, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateUserProfile() {
      const { firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, avatarColor, unitSystem } = this.completeAccountForm;
      const result = await UserApi.updateUserProfile(
        this.$auth.user.id,
        this.$auth.user.email,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        avatarColor,
        unitSystem,
        null,
        null
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onEmailVerification() {
      this.$v.verificationForm.$touch();
      if (this.$v.verificationForm.$invalid) {
        this.$root.$emit("universal-error-message", "verification form invalid");
      } else {
        await this.loginByToken(this.verificationForm.token);
      }
    },
    async onAccountCompleted() {
      this.$v.completeAccountForm.$touch();
      if (this.$v.completeAccountForm.$invalid || !this.completeAccountForm.agreementCheckbox) {
        this.$root.$emit("universal-error-message", "Please complete the form and agree to Terms to finish your account registration.");
        return;
      }

      const resetPasswordResult = await this.resetPassword();
      if (!resetPasswordResult.success) {
        return;
      }

      const updateUserProfileResult = await this.updateUserProfile();
      if (!updateUserProfileResult.success) {
        return;
      }

      if (this.completeAccountForm.avatarUrl) {
        const uploadUserAvatarResult = await this.UploadUserAvatar();
        if (!uploadUserAvatarResult.success) {
          return;
        }
      }

      try {
        await this.$auth.logout({ redirectLocation: { name: "none" } });
        this.activeForm = "success";
      } catch (error) {
        this.$root.$emit("universal-error-message", "Unable to redirect you to the login screen. Please navigate there to log in with your new password.");
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawSection section-style="border">
      <SuawForm v-if="activeForm === 'verification'" @submit="onEmailVerification">
        <template #form>
          <SuawHeading level="3" content="Verify Your Email" alignment="center" />
          <SuawTextInput
            id="token-pw-reset"
            v-model="verificationForm.token"
            type="text"
            placeholder="Enter Code"
            is-required
            no-label
            :state="formFieldState($v, 'verificationForm', 'token')"
            :error-message="validateErrors($v.verificationForm.token, 'Verification code')"
            @input="uppercaseToken"
            @blur="$v.verificationForm.token.$touch()"
          />
          <SuawParagraph
            size="small"
            text="Reset your password by clicking the verification link we've sent to your email. Or, enter the code from your verification email above."
            alignment="left"
          />
          <SuawButton size="large" type="primary" button-text="Verify Code" />
        </template>
      </SuawForm>

      <SuawForm v-if="activeForm === 'complete-account'">
        <template #form>
          <SuawHeading level="3" content="Complete Your Account" alignment="center" />
          <SuawTextInput
            id="first-name-complete-account"
            v-model="completeAccountForm.firstName"
            type="text"
            placeholder="First Name"
            is-required
            label="First Name:"
            :state="formFieldState($v, 'completeAccountForm', 'firstName')"
            :error-message="validateErrors($v.completeAccountForm.firstName, 'First name')"
            @blur="$v.completeAccountForm.firstName.$touch()"
          />
          <SuawTextInput
            id="last-name-complete-account"
            v-model="completeAccountForm.lastName"
            type="text"
            placeholder="Last Name"
            is-required
            label="Last Name:"
            :state="formFieldState($v, 'completeAccountForm', 'lastName')"
            :error-message="validateErrors($v.completeAccountForm.lastName, 'Last name')"
            @blur="$v.completeAccountForm.lastName.$touch()"
          />
          <SuawInputGroup direction="column" field-one-size="1" field-two-size="10">
            <SuawLabel label-text="Display Name:" size="small" />
            <SuawRadio id="full" v-model="completeAccountForm.isLastNameHidden" name="chooseName" label="Full Name" :native-value="false" />
            <SuawRadio id="firstLast" v-model="completeAccountForm.isLastNameHidden" name="chooseName" label="First Name & Last Initial" :native-value="true" />
          </SuawInputGroup>
          <SuawTextInput
            id="password-complete-account"
            v-model="completeAccountForm.password"
            type="password"
            placeholder="Enter Password"
            is-required
            label="Password:"
            :state="formFieldState($v, 'completeAccountForm', 'password')"
            :error-message="validateErrors($v.completeAccountForm.password, 'Password')"
            @blur="$v.completeAccountForm.password.$touch()"
          />
          <SuawTextInput
            id="password-confirm-complete-account"
            v-model="completeAccountForm.confirmPassword"
            type="password"
            placeholder="Confirm Password"
            is-required
            label="Confirm Password:"
            :state="formFieldState($v, 'completeAccountForm', 'confirmPassword')"
            :error-message="validateConfirmPasswordError"
            @blur="$v.completeAccountForm.confirmPassword.$touch()"
          />
          <SuawSelect
            v-model="completeAccountForm.countryAbbrev"
            label="Country"
            placeholder="Select your country"
            is-required
            :options="formattedCountries"
            :state="formFieldState($v, 'completeAccountForm', 'countryAbbrev')"
            :error-message="validateErrors($v.completeAccountForm.countryAbbrev, 'Country')"
            success-message=""
            @blur="$v.completeAccountForm.countryAbbrev.$touch()"
          />
          <SuawLabel label-text="Unit of Length:" size="small" />
          <SuawInputGroup direction="row" :centered="true">
            <SuawRadio id="miles" v-model="completeAccountForm.unitSystem" name="chooseUnit" label="Miles" :native-value="'imperial'" />
            <SuawRadio id="kilometers" v-model="completeAccountForm.unitSystem" name="chooseUnit" label="Kilometers" :native-value="'metric'" />
          </SuawInputGroup>
          <SuawTextInput
            id="postal-code-complete-account"
            v-model="completeAccountForm.postalCode"
            type="text"
            placeholder="Postal Code"
            is-required
            label="Postal Code:"
            :state="formFieldState($v, 'completeAccountForm', 'postalCode')"
            :error-message="validateErrors($v.completeAccountForm.postalCode, 'Postal code')"
            @blur="$v.completeAccountForm.postalCode.$touch()"
          />
          <SuawLabel label-text="Avatar Style:" size="small" />
          <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
            <SuawRadio id="color" v-model="avatarStyle" name="chooseAvatar" label="Color" native-value="color" />
            <SuawRadio id="image" v-model="avatarStyle" name="chooseAvatar" label="Image" native-value="image" />
          </SuawInputGroup>

          <SuawInputGroup direction="column">
            <SuawFileUpload v-if="showImageUpload" upload-title="Upload User Image" @file-selected="convertFile" />
            <SuawDropdown
              v-else
              dropdown-button-size="medium"
              :items="avatarColorOptions"
              dropdown-type="mutable"
              button-type="ghost-outline"
              button-class-name="suaw-dropdown__button--fill"
              label="Avatar Color"
              @item-click="handleAvatarColorSelect"
            />
          </SuawInputGroup>
          <SuawCheckbox
            v-model="completeAccountForm.agreementCheckbox"
            is-required
            label="By checking this box, I agree to the <a href='/resources/tos' target='_blank'>Terms of Service</a> and <a href='/resources/code-of-conduct' target='_blank'>Code of Conduct</a>"
            :state="formFieldState($v, 'completeAccountForm', 'agreementCheckbox')"
            :error-message="validateErrors($v.completeAccountForm.agreementCheckbox, 'Agreement')"
          />
          <SuawButton size="large" type="primary" button-text="Finish Account" @click="onAccountCompleted" />
        </template>
      </SuawForm>

      <SuawForm v-if="activeForm === 'success'" @submit="redirectToSignIn">
        <template #form>
          <SuawHeading level="3" content="Your account is complete!" alignment="center" />
          <SuawParagraph size="small" text="You have been logged out and must log back in to continue." alignment="left" />
          <SuawButton size="large" type="primary" button-text="Log In" />
        </template>
      </SuawForm>
    </SuawSection>
  </SuawMainContent>
</template>
